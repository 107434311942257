import { Injectable } from '@angular/core';
import { SqliteWorkerService } from './sqlite-worker.service';

@Injectable({
  providedIn: 'root'
})
export class SqliteInitService {

  get databaseEstablished$() { return this.sqliteWorkerService.databaseEstablished$; }
  get databaseEstablished() { return this.sqliteWorkerService.databaseEstablished; }

  constructor(private sqliteWorkerService: SqliteWorkerService) {}

  init() {
    this.sqliteWorkerService.initWorkerAndDatabase();
  }
}
