import { environment } from '../../../../environments/environment';

export class URL extends globalThis.URL {

  private urlObj: globalThis.URL;

  constructor(url: string | globalThis.URL, base: string | globalThis.URL) {
    super(url, base);
    return this.getTrustedUrl(url, base);
  }

  private getTrustedUrl(url: string | globalThis.URL, base: string | globalThis.URL) {
    this.urlObj = new globalThis.URL(url, base);
    let workerUrl: any;

    if (window.trustedTypes && window.trustedTypes.createPolicy) {
      const ppWorkerPolicy = window.trustedTypes.createPolicy('ppWorkerPolicy', {
        createScriptURL: src => this.checkUrl(src),
      });
      workerUrl = ppWorkerPolicy.createScriptURL(this.urlObj.href);
    } else {
      workerUrl = url;
    }
    return workerUrl;
  }

  private checkUrl(url: string) {
    if (this.urlObj.origin === environment.host) {
      return url;
    }
    throw new TypeError('Invalid worker url');
  }
}
